<template>
  <main>
    <div class="first-body-section">
      <div class="area">
        <ul class="circles">
          <li>
            <img
              class="icon"
              src="https://matriclive.com/images/icon.png"
              style="transform: matrix(1, 0, 0, 1, 0, -500)"
            />
          </li>
          <li></li>
          <li></li>
          <li></li>
          <li>
            <img
              class="icon"
              src="https://matriclive.com/images/icon.png"
              style="transform: matrix(1, 0, 0, 1, 0, -500)"
            />
          </li>
          <li></li>
          <li></li>
          <li></li>
          <li>
            <img
              class="icon"
              src="https://matriclive.com/images/icon.png"
              style="transform: matrix(1, 0, 0, 1, 0, -500)"
            />
          </li>
          <li>
            <img
              class="icon"
              src="https://matriclive.com/images/icon.png"
              style="transform: matrix(1, 0, 0, 1, 0, -500)"
            />
          </li>
        </ul>
      </div>
      <div class="m-live-section fade-in">
        <div class="title">Matric Live</div>
        <div class="sub-title">A Click Away from A Bachelor</div>
        <img src="@/assets/homepage/icon 1.svg" class="matriclive_logo" />
        <div class="available-text">Available Now</div>
        <div class="download-links">
          <a href="https://apps.apple.com/sz/app/matric-live/id1510032111" target="_blank">
            <img src="@/assets/app_strore_badges/apple-app-store-badge.svg" alt />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.SA.matriclive&hl=en&gl=US" target="_blank">
            <img src="@/assets/app_strore_badges/google-play-badge.svg" alt />
          </a>
        </div>
      </div>
      <img
        class="init-slide-in"
        id="init-slide"
        src="@/assets/homepage/Group 2068.svg"
        alt
      />
      <!-- <img
        class="init-slide-in"
        id="init-slide"
        src="@/assets/homepage/main_look.svg"
        alt
      /> -->
    </div>
    <hr class="line" />
    <div class="learning-section">
      <h1>How we make Learning Exciting?</h1>
      <p><b>Matric Live</b> is a multifunctional education app that is reinventing the way pupils learn by making learning contextual, visual and not just theoretical. It supports over 16 subjects and it is currently free as pupils are writing their exams.</p>
    
      <div class="made-fun1">
        <div class="made-fun1_1">
          <h2>Learning Made Fun</h2>
          <p>An easier way to <b>navigate topics</b> within <br> the app.</p>
          <img class="slide-in from-bottom" src="@/assets/homepage/iPhone 12 Pro Max – 15.svg" alt />
        </div>
        <div class="made-fun1_2">
          <h2>Fun, Interactive Learning</h2>
          <p><b>Study</b> at your<br> own <b>pace</b></p>
          <img class="slide-in from-top" src="@/assets/homepage/1 589602.svg" alt />
        </div>
      </div>

      <div class="made-fun2">
        <div class="made-fun2_1">
          <h2>Gamification</h2>
          <p><b>Challenge</b> your friends on <b>competitive exams</b> <br>and earn points.</p>
          <img class="slide-in from-bottom" src="@/assets/homepage/iPhone 12 Pro Max – 14 2.svg" alt />
        </div>
        <div class="made-fun2_2">
          <h2>Live Exam Simulation</h2>
          <p>Don't Just View <b>Past Papers</b><br> Write them.</p>
          <img class="slide-in from-top" src="@/assets/homepage/iPhone 12 Pro Max – 15 (1).svg" alt />
        </div>
      </div>
    </div>
    
    <hr class="line" />
    <div class="end-section">
      <p class="live-exams-paragrap">
        <b>Live Exams</b> is an exam simulation which enables Learners to write
        past exam questions on their device while keeping track of
        questions/Topics they often get incorrect for deep analysis and for us
        to improve learning systems.
      </p>
      <div class="screen-demos">
        <img
          class="side-pics"
          src="@/assets/homepage/iPhone 11 Pro Max — 13 5 (1).svg"
          alt
          height="347"
          width="206"
        />
        <img
          class="slide-in from-bottom remove-responsive"
          src="@/assets/homepage/ezgif-7-4097c67b273c 1 (1).svg"
          alt
          height="300"
          width="620"
        />
        <img
          class="side-pics"
          src="@/assets/homepage/iPhone 11 Pro Max — 13 6 (1).svg"
          alt
          height="347"
          width="200.11"
        />
      </div>
    </div>
    <div class="download-now-section">
      <div class="title">DOWNLOAD NOW</div>
      <div class="download-links">
        <a href="https://apps.apple.com/sz/app/matric-live/id1510032111" target="_blank">
          <img src="@/assets/app_strore_badges/apple-app-store-badge.svg" alt />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.SA.matriclive&hl=en&gl=US" target="_blank">
          <img src="@/assets/app_strore_badges/google-play-badge.svg" alt />
        </a>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "HomePage",
  methods: {
    openTab(languageType, languageTypeContent) {
      // evn = window.event;
      // alert(evn);
      var card = document.querySelector(".card");
      card.classList.toggle("is-flipped");

      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tab");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      
      document.getElementById(languageType).style.display = "block";
      document.getElementById(languageTypeContent).style.display = "block";
    },
  },
 
  mounted() {
    const sliders = document.querySelectorAll(".slide-in");

    const appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -250px 0px",
    };

    const appearOnScroll = new IntersectionObserver(function (
      entries,
      appearOnScroll
    ) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add("appear");
          appearOnScroll.unobserve(entry.target);
        }
      });
    },
    appearOptions);

    sliders.forEach((slider) => {
      appearOnScroll.observe(slider);
    });

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.icon {
  width: 30px;
  height: 30px;
}
main {
  background-image: url("../assets/careerspage/Desktop - 1.svg");
  background-size: auto;
  overflow: hidden;
}

.first-body-section {
  margin: 28px 0px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.first-body-section img {
  margin-left: 10px;
}

.matriclive_logo {
  width: 60px;
  height: 60px;
}

@keyframes animationgradienttitle {
  0% {
    background-position: 0 1600px;
  }
  100% {
    background-position: 1600px 0;
  }
}

.title {
  font-size: 5em;
  font-weight: 900;
  font-family: Avenir,Helvetica,Arial,sans-serif !important;
  color: #e96812;
  background: -webkit-linear-gradient(left, #8b5af3, #eda04b, #f05a23);
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 3s;
  animation-name: animationgradienttitle;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.sub-title {
  font-size: xx-large;
  margin: 14px 0px;
  font-weight: 900;
}

.download-links {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.download-links img {
  width: 12em;
  height: 50px;
}

.available-text {
  margin: 28px 0px;
  font-size: 25px;
  font-weight: 800;
  color: #0000008c;
  letter-spacing: -1px;
}

.m-live-section {
  align-self: center;
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

.first-body-section img#init-slide{
    width: auto;
    height: 550px;
  }

#init-slide {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.init-slide-in {
  animation: init-slide-in 2s forwards;
  -webkit-animation: init-slide-in 2s forwards;
}

@keyframes init-slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes init-slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

hr {
  border-top: 2px dashed mediumpurple;
  width: 33%;
  margin: 5% auto;
}

.scnd-screen-main-div {
  display: flex;
  justify-content: center;
}

.display-text {
  width: 45%;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: normal;
  padding: 10px;
  transform: translate(-50%, -50%);
}

.frst-text {
  position: relative;
  text-align: center;
  color: white;
}

.scnd-text {
  text-align: end;
  margin: 38px 0px;
  font-size: large;
}

.thrd-text {
  text-align: center;
  margin: 38px 0px;
  font-size: large;
}

.from-bottom {
  grid-column: 2 / 3;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.from-bottom {
  transition: opacity 250ms ease-in, -webkit-transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in,
    -webkit-transform 1s ease-in;
  opacity: 0;
}

.from-bottom.appear {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.from-top {
  grid-column: 2 / 3;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.from-top {
  transition: opacity 250ms ease-in, -webkit-transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in,
    -webkit-transform 1s ease-in;
  opacity: 0;
}

.from-top.appear {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.from-left {
  grid-column: 2 / 3;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.from-left {
  transition: opacity 250ms ease-in, -webkit-transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in;
  transition: opacity 250ms ease-in, transform 1s ease-in,
    -webkit-transform 1s ease-in;
  opacity: 0;
}

.from-left.appear {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.thrd-screen-main-div {
  display: flex;
  justify-content: space-evenly;
}

.past-papers-section {
  display: block;
  width: 40%;
}

.past-papers-section > #first {
  text-align: center;
  font-size: larger;
}

.pp-heading {
  font-weight: bold;
  color: #e96812;
  font-size: xxx-large;
  text-align: center;
  margin-bottom: 50px;
}

.sub-text {
  font-size: larger;
  margin: 50px 0px;
}

.t2 {
  display: flex;
  justify-content: flex-end;
}

.t1 {
  display: flex;
  justify-content: flex-start;
}

.t1 > .sub-text {
  margin-bottom: 0px;
}

.t3-sub-head {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}

.sub-text > #step-further {
  font-weight: 700;
  font-size: x-large;
}

.past-papers-section > .t3-head > .sub-text {
  text-align: center;
}

.number-circle {
  color: white;
  background-color: #e96812;
  padding: 11px 16px;
  border-radius: 50%;
  margin-right: 20px;
  font-weight: 700;
  width: 50px;
  height: 50px;
}

.t3-head {
  text-align: center;
}

.end-section {
  text-align: center;
  justify-content: space-around;
  padding: 10px;
}

.end-section > p {
  font-size: larger;
  text-align: center;
}

.screen-demos {
  display: flex;
  justify-content: inherit;
  margin: 8% 0px;
}

.side-pics {
  margin-top: 5%;
}

.download-now-section {
  text-align: center;
  margin: 4%;
}

.download-now-section .title {
  margin: 4%;
  font-size: 4em;
}

.download-now-section .download-links {
  text-align: center;
  display: block;
  margin-bottom: 2%;
}

button.web-btn {
  background-color: white;
  height: 46px;
  border-radius: 50px;
  border: thin;
  width: 163px;
  outline: none;
  box-shadow: 0px 5px 3px #0000008f;

  font-weight: bold;
  /* color: #f47622; */
  background: linear-gradient(90.32deg, #7932E8 28.37%, #9B51E0 97.47%);
  margin: 25px;
  font-size: 15px;
}

img#init-slide {
  width: 35%;
  margin-top: 50px;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.4);
    text-shadow: 6px -1px 16px #ffffff94;
  }
}

/* .area {
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
  position: absolute;
} */

/* This is blocking the div underneath */
.circles {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgb(242 99 35 / 11%);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.content {
  width: 100%;
  position: absolute;
  top: 30vh;
  text-align: center;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.learning-section {
  justify-content: space-around;
  padding: 10px;
}

.learning-section h1 {
  font-size: xx-large;
  font-weight: 900;
}

.learning-section p {
  font-size: larger;
}

.learning-section .made-fun1 .made-fun1_1 h2 {
    font-size: xx-large;
    font-weight: 900;
    background: -webkit-linear-gradient(right, #8E35AC, #8A54E9, #8B5AF4);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.learning-section .made-fun1 .made-fun1_2 h2 {
    font-size: x-large;
    font-weight: 700;
    background: -webkit-linear-gradient(right, #8E35AC, #8A54E9, #8B5AF4);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.learning-section .made-fun2 .made-fun2_1 h2 {
    font-size: x-large;
    font-weight: 700;
    background: -webkit-linear-gradient(right, #5558FF, #6768F6);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.learning-section .made-fun2 .made-fun2_2 h2 {
    font-size: x-large;
    font-weight: 700;
    color: #E7682B;
    /* background: -webkit-linear-gradient(right, #5558FF, #6768F6); */
    /* -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.learning-section .made-fun2 .made-fun2_1 img,
.learning-section .made-fun2 .made-fun2_2 img,
.learning-section .made-fun1 .made-fun1_1 img,
.learning-section .made-fun1 .made-fun1_2 img {
    width: 312px;
    height: 640px;
}

.learning-section .made-fun2 .made-fun2_2,
.learning-section .made-fun1 .made-fun1_2 {
    padding: 10%;
}

.learning-section .made-fun1 {
  display: flex;
  margin-top: 8%;
  margin-left: 3%;
  margin-right: 3%;
  justify-content: space-evenly;
}

.learning-section .made-fun2 {
  display: flex;
  margin-left: 3%;
  margin-right: 3%;
  justify-content: space-evenly;
}


.cercle {
  width: 0px;
  height: 0px;
  position: absolute;
}

.light .cercle {
  background: white;
}
.dark .cercle {
  background: black;
}

.anim {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  -webkit-animation: touch 1.2s ease-out;
  animation: touch 1.2s ease-out;
}

@media screen and (max-width: 950px) {
  main {
    overflow-x: hidden;
  }
  .first-body-section {
    display: block;
  }

  .first-body-section div.m-live-section .title{
    font-size: xxx-large;
  }
  .first-body-section div.m-live-section .sub-title{
    font-size: x-large;
  }
  .first-body-section img#init-slide{
    width: auto;
    height: 200px;
  }

  .first-body-section div.m-live-section .download-links{
    display: block;
  }
  .first-body-section div.m-live-section .download-links img:first-child{
    margin-bottom: 3%;
  }

  .first-body-section div.m-live-section {
    width: 100%;
  }

  .sub-text {
    font-size: initial;
  }
  .t2 {
    justify-content: initial;
  }
  .t3-head img {
    width: 43px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .end-section .screen-demos .remove-responsive {
    display: none;
  }
  .end-section .screen-demos {
    overflow: hidden;
  }

  .download-now-section .title {
    font-size: xx-large;
  }

  .download-now-section .download-links img:first-child {
    margin-bottom: 3%;
  }

  .learning-section .made-fun2 .made-fun2_1 img,
.learning-section .made-fun2 .made-fun2_2 img,
.learning-section .made-fun1 .made-fun1_1 img,
.learning-section .made-fun1 .made-fun1_2 img {
    width: 206px;
    height: 347px;
}

.learning-section .made-fun1 {
  display: block;
  margin-top: 8%;
  margin-left: 3%;
  margin-right: 3%;
  justify-content: space-evenly;
}

.learning-section .made-fun2 {
  display: block;
  margin-left: 3%;
  margin-right: 3%;
  justify-content: space-evenly;
}
}

@media screen and (min-width: 418px) and (max-width: 950px) {
  .download-now-section .download-links img:first-child {
    margin-bottom: 0%;
  }
  .first-body-section div.m-live-section .download-links img:first-child{
    margin-bottom: 0%;
  }
}
</style>
